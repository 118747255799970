import React, { FC, useState } from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import { Helmet } from 'react-helmet';
import { navigate } from 'gatsby';

const BirchPage: FC<any> = ( { data } ) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  let hasImages = null;

  function checkImages () {
    if (data.birkiPhotos.edges[selectedIndex]) {
      hasImages = true;
      return <div className='itemImages'>
        <div className='photo'>
          <div className='large' key={data.birkiPhotos.edges[selectedIndex].node.id}>
            <Img
              fluid={data.birkiPhotos.edges[selectedIndex].node.childImageSharp.fluid}
              alt={data.birkiPhotos.edges[selectedIndex].node.base}
            />
          </div>
        </div>
        <div className='thumbnails'>
          {data.birkiPhotos.edges.map((image, index) => (
            <div className={index === selectedIndex ? 'thumb selected' : 'thumb'} key={image.node.id} onClick={(() => setSelectedIndex(index))}>
              <Img
                fixed={image.node.childImageSharp.fixed}
                alt={image.node.base}
              />
            </div>
          ))}
        </div>
      </div>;
    }
  }
  return (
    <Layout>
      <SEO title="Ilmbjörk" />
      <Helmet bodyAttributes={{ class: 'info-page' }} />
      <div className='itemContent'>
        <button className='backBtn' onClick={() => navigate('/')}>Til baka</button>
        <h3>Ilmbjörk (<i>Betula pubescens</i> Ehrh.)</h3>
        <p>
          Um er að ræða íslensk kvæmi ilmbjarkar. Ilmbjörk er harðgerð og nokkuð
          hraðvaxta frumherjatrjátegund. Æskuvöxtur er oftast ágætur ef hentugt land
          er valið og jarðvinnsla, þar sem hún á við.
        </p>
        <p>
          Þrífst vel í flestum landshlutum en á erfiðara uppdráttar í mjög hafrænu
          loftslagi á annesjum og við opið haf (saltskemmdir). Hentar mjög vel til
          uppgræðslu á þurrum og lítt grónum eyðimörkum inn til landsins. Virðist
          þola þurrk einkar vel, ekki síst þar sem lítið er um samkeppnisgróður. Sem
          dæmi um þetta er að birki er fljótt að breiðast út á hraunum þótt þar sé
          grunnvatnsstaðan afar lág og breytileg. Er nokkuð viðkvæmt fyrir
          næturfrostum s.s. á sléttum og í dalbotnum.
        </p>
        <p>
          Bestu svæðin á Íslandi eru: Fljótsdalshérað og Vopnafjörður innanverður,
          innsveitir Suðausturlands, Suðurlandsundirlendis og Borgarfjarðar ásamt
          austurdölum Austur-Húnavatnssýslu og innanverðum Skagafirði, Eyjafirði,
          S.-Þingeyjarsýslu og Öxarfirði.
        </p>
        <p>
          Fjölgar sér mjög ríkulega náttúrulega með stubbaskotum og fræplöntum og fræið getur borist
          langt frá vaxtarstað (frekar létt og vængjað fræ).
        </p>
        <p>
          Borðviðarnýtingarhlutfall er nánast ekkert og iðnviðarnýting lítil vegna
          lélegs vaxtarforms. Vinsæll eldiviður og eldiviðartekja möguleg en trauðla
          arðbær.
        </p>
      </div>
      {checkImages()}
    </Layout>
  );
};

export default BirchPage;

export const pageQuery = graphql `
  query {
    birkiPhotos: allFile(filter: {relativeDirectory: {eq: "birki"}}) {
      edges {
        node {
          id
          base
          childImageSharp {
            fluid(maxWidth: 595, maxHeight:460, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
          childImageSharp {
            fixed(height: 135, width: 135, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  }
`;

